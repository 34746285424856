import axios from 'axios';

export const Url = 'https://api.misarecrut.com/api';
export const UrlStorage = 'https://api.misarecrut.com/storage/';

export const authHeaders = () => ({
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  });

const authService = {
  login: async (email, password) => {
    try {
      const response = await axios.post(`${Url}/admin/login`, { email, password });
      return response.data;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  },

  logout: async () => {
    try {
      const response = await axios.post(`${Url}/admin/logout`, null, authHeaders());
      return response.data;

    } catch (error) {
      console.error('Logout error:', error);
      throw error;
    }
  },

  indexDomain: async () => {
    try {
      const response = await axios.get(`${Url}/gestion/domains`, authHeaders());
      return response.data;
    } catch (error) {
      console.error('Get Domain error:', error);
      throw error;
    }
  },

    indexVille:
    async () => {
    try {
        const response = await axios.get(`${Url}/gestion/villes`, authHeaders());
        //  console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Get Ville error:', error);
        throw error;
    }
    
    },

    storeDomain: async (name_domain,metier) => {
        try {
          const formData = new FormData();
            formData.append('name_domain', name_domain);
            formData.append('metier', metier);
            // console.log('formData:',formData.get('name_domain'),formData.get('metier'))
          const response = await axios.post(`${Url}/gestion/domain/add`, formData, authHeaders());
        //   console.log(response);
          return response;
        } catch (error) {
          console.error('Navigation error:', error);
          throw error;
        }
      },

      
      storeVille: async (name_ville) => {
        try {
          const formData = new FormData();
            formData.append('name_ville', name_ville);
            // console.log('formData:',formData.get('name_ville'))
          const response = await axios.post(`${Url}/gestion/ville/add`, formData, authHeaders());
        //   console.log(response);
          return response;
        } catch (error) {
          console.error('Navigation error:', error);
          throw error;
        }
      },


      updateDomain: async (name_domain,metier,id) => {
        try {
          const formData = new FormData();
            formData.append('name_domain', name_domain);
            formData.append('metier', metier);
            // console.log('formData:',formData.get('name_domain'),formData.get('metier'))
          const response = await axios.post(`${Url}/gestion/domain/update/${id}`, formData, authHeaders());
        //   console.log(response);
          return response;
        } catch (error) {
          console.error('Navigation error:', error);
          throw error;
        }
      },

      
      updateVille: async (name_ville,id) => {
        try {
          const formData = new FormData();
            formData.append('name_ville', name_ville);
            // console.log('formData:',formData.get('name_ville'))
          const response = await axios.post(`${Url}/gestion/ville/update/${id}`, formData, authHeaders());
        //   console.log(response);
          return response;
        } catch (error) {
          console.error('Navigation error:', error);
          throw error;
        }
      },

      destroyDomain: async (id) => {
        try {
          const response = await axios.delete(`${Url}/gestion/domain/delete/${id}`, authHeaders());
          console.log(response);
          return response;
        } catch (error) {
          console.error('DemandCreate error:', error);
          throw error;
        }
      },


      
      destroyVille: async (id) => {
        try {
          const response = await axios.delete(`${Url}/gestion/ville/delete/${id}`, authHeaders());
          console.log(response);
          return response;
        } catch (error) {
          console.error('DemandCreate error:', error);
          throw error;
        }
      },


    indexUser:
    async () => {
    try {
        const response = await axios.get(`${Url}/gestion/users`, authHeaders());
        //  console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Get Users error:', error);
        throw error;
    }
    
    },

    destroyUser: async (id) => {
        try {
          const response = await axios.delete(`${Url}/gestion/user/delete/${id}`, authHeaders());
          console.log(response);
          return response;
        } catch (error) {
          console.error('DestroyUser error:', error);
          throw error;
        }
      },

    indexEntreprise:
    async () => {
    try {
        const response = await axios.get(`${Url}/gestion/entreprises`, authHeaders());
        //  console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Get Entreprises error:', error);
        throw error;
    }
    },

    destroyEntreprise: async (id) => {
        try {
          const response = await axios.delete(`${Url}/gestion/entreprise/delete/${id}`, authHeaders());
          console.log(response);
          return response;
        } catch (error) {
          console.error('destroyEntreprise error:', error);
          throw error;
        }
      },



    indexPackage:
    async () => {
    try {
        const response = await axios.get(`${Url}/packages`, authHeaders());
        // console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Get Package error:', error);
        throw error;
    }
    
    },

    indexPricing:
    async () => {
    try {
        const response = await axios.get(`${Url}/pricing`, authHeaders());
        // console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Get Pricing error:', error);
        throw error;
    }
    
    },
  // Add other index functions similarly


    storePackage: async (nm,price,pointsold,duration,durationSolde) => {
        try {
        const formData = new FormData();
            formData.append('name', nm);
            formData.append('price', price);
            formData.append('pointsold', pointsold);
            formData.append('duration', duration);
            formData.append('durationSolde', durationSolde);
        const response = await axios.post(`${Url}/package/add`, formData, authHeaders());
        //   console.log(response);
        return response;
        } catch (error) {
        console.error('Navigation error:', error);
        throw error;
        }
    },

    showUser: async () => {
        try {
        const response = await axios.get(`${Url}/admin/user`, authHeaders());
        //   console.log(response);
        return response;
        } catch (error) {
        console.error('Navigation error:', error);
        throw error;
        }
    },

    register: async (nm,email,password) => {
        try {
        const formData = new FormData();
            formData.append('name', nm);
            formData.append('email', email);
            formData.append('password', password);
        const response = await axios.post(`${Url}/admin/add`, formData, authHeaders());
        //   console.log(response);
        return response;
        } catch (error) {
        console.error('Navigation error:', error);
        throw error;
        }
    },

    

    changePassword: async (current_password,new_password) => {
        try {
        const formData = new FormData();
            formData.append('current_password', current_password);
            formData.append('new_password', new_password);
        const response = await axios.post(`${Url}/admin/change-password`, formData, authHeaders());
        //   console.log(response);
        return response;
        } catch (error) {
        console.error('Navigation error:', error);
        throw error;
        }
    },


    update: async (nm,email) => {
        try {
        const formData = new FormData();
            formData.append('name', nm);
            formData.append('email', email);
        const response = await axios.post(`${Url}/admin/update`, formData, authHeaders());
        //   console.log(response);
        return response;
        } catch (error) {
        console.error('Navigation error:', error);
        throw error;
        }
    },




};

export default authService;
