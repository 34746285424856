import React from 'react';
import AuthentificationAdmin from './AuthentificationAdmin/AuthentificationAdmin';
import AppManagement from './AppManagment/AppManagement';
import UserManagement from './UserManagement/UserManagement';
import PricingList from './FinancManagment/PricingList';
import PricingForm from './FinancManagment/PricingForm';
import PackageList from './FinancManagment/PackageList';
import PackageForm from './FinancManagment/PackageForm';

const Contents = ({ activeSection }) => {
    return (
        <div className="w-full overflow-auto">
            <div className="w-[95%] mx-auto">
                {activeSection === 'AuthentificationAdmin' && <AuthentificationAdmin />}
                {activeSection === 'AppManagement' && <AppManagement />}
                {activeSection === 'UserManagement' && <UserManagement />}
                <div className="px-4 py-8">

                    {activeSection === 'Pricing' && 
                        <>
                            <div className="mb-8">
                                <PricingForm />
                            </div>
                            <div>
                                <PricingList />
                            </div>
                        </>
                    }
                    {activeSection === 'UpdateBalance' && <>
                                <div className="mb-8">
                                    <PackageForm />
                                </div>
                                <div>
                                    <PackageList />
                                </div>
                    </>
                    }

                </div>
            </div>
            
        </div>
    );
};

export default Contents;
