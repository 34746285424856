import React, { useState, useEffect } from 'react';
import { BiPlus, BiEdit, BiTrash } from 'react-icons/bi'; // Icons for add, edit, and delete
import authService from '../../../services/authService';

const AppManagement = () => {
    const [domains, setDomains] = useState([]);
    const [villes, setVilles] = useState([]);
    const [formData, setFormData] = useState({
        name_domain: '',
        metier: '',
        name_ville: '',
    });
    const [editingItemId, setEditingItemId] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const domainResponse = await authService.indexDomain();
            const villeResponse = await authService.indexVille();
            setDomains(domainResponse.data);
            setVilles(villeResponse.data);
        } catch (error) {
            console.error('Fetch data error:', error);
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e, type) => {
        e.preventDefault();
        try {
            if (editingItemId !== null) {
                if (type === 'domain') {
                    await authService.updateDomain(formData.name_domain, formData.metier, editingItemId);
                } else if (type === 'ville') {
                    await authService.updateVille(formData.name_ville, editingItemId);
                }
                setEditingItemId(null);
            } else {
                if (type === 'domain') {
                    await authService.storeDomain(formData.name_domain, formData.metier);
                } else if (type === 'ville') {
                    await authService.storeVille(formData.name_ville);
                }
            }
            setFormData({ name_domain: '', metier: '', name_ville: '' });
            fetchData(); // Refresh the data after submitting
        } catch (error) {
            console.error(`Error submitting ${type}:`, error);
        }
    };

    const handleDelete = async (id, type) => {
        try {
            if (type === 'domain') {
                await authService.destroyDomain(id);
            } else if (type === 'ville') {
                await authService.destroyVille(id);
            }
            fetchData(); // Refresh the data after deleting
        } catch (error) {
            console.error(`Error deleting ${type}:`, error);
        }
    };

    const handleEdit = async (id, type) => {
        try {
            let itemData = {};
            if (type === 'domain') {
                itemData = domains.find((domain) => domain.id === id);
            } else if (type === 'ville') {
                itemData = villes.find((ville) => ville.id === id);
            }
            setFormData({ ...formData, ...itemData });
            setEditingItemId(id);
        } catch (error) {
            console.error(`Error editing ${type}:`, error);
        }
    };

    return (
        <div className="container mx-auto mt-6 p-4">
            <div className="grid grid-cols-2 space-x-8">
                {/* Domain section */}
                <div>
                    <h2 className="text-xl font-bold mb-4">Domain</h2>
                    <form onSubmit={(e) => handleSubmit(e, 'domain')}>
                        <div className='flex space-x-3'>
                            <div className="mb-4">
                                <label htmlFor="name_domain" className="block text-sm font-medium text-gray-700">Name Domain</label>
                                <input type="text" id="name_domain" name="name_domain" value={formData.name_domain} onChange={handleChange} className="mt-1 p-2 border border-gray-300 rounded-md w-full" required />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="metier" className="block text-sm font-medium text-gray-700">Metier</label>
                                <input type="text" id="metier" name="metier" value={formData.metier} onChange={handleChange} className="mt-1 p-2 border border-gray-300 rounded-md w-full" required />
                            </div>
                        </div>
                        <button type="submit" className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600">{editingItemId !== null ? 'Update' : 'Add'} Domain</button>
                    </form>
                    <div className='max-h-[65vh] mt-5 overflow-auto'>
                        <ul className="mt-4">
                            {domains.map((domain) => (
                                <li key={domain.id} className="flex justify-between items-center border-b border-gray-200 py-2">
                                    <span>{domain.name_domain}</span>
                                    <div>
                                        <button onClick={() => handleDelete(domain.id, 'domain')} className="text-red-500 hover:text-red-700 mr-2"><BiTrash /></button>
                                        <button onClick={() => handleEdit(domain.id, 'domain')} className="text-blue-500 hover:text-blue-700"><BiEdit /></button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                {/* Ville section */}
                <div>
                    <h2 className="text-xl font-bold mb-4">Ville</h2>
                    <form onSubmit={(e) => handleSubmit(e, 'ville')}>
                        <div className="mb-4">
                            <label htmlFor="name_ville" className="block text-sm font-medium text-gray-700">Name Ville</label>
                            <input type="text" id="name_ville" name="name_ville" value={formData.name_ville} onChange={handleChange} className="mt-1 p-2 border border-gray-300 rounded-md w-full" required />
                        </div>
                        <button type="submit" className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600">{editingItemId !== null ? 'Update' : 'Add'} Ville</button>
                    </form>
                    <div className='max-h-[65vh] mt-5 overflow-auto'>
                        <ul className="mt-4">
                            {villes.map((ville) => (
                                <li key={ville.id} className="flex justify-between items-center border-b border-gray-200 py-2">
                                    <span>{ville.name_ville}</span>
                                    <div>
                                        <button onClick={() => handleDelete(ville.id, 'ville')} className="text-red-500 hover:text-red-700 mr-2"><BiTrash /></button>
                                        <button onClick={() => handleEdit(ville.id, 'ville')} className="text-blue-500 hover:text-blue-700"><BiEdit /></button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppManagement;
