import React, { useState } from 'react';
import { useAuth } from '../../services/AuthContext';
import authService from '../../services/authService';

const Sidebar = ({ setActiveSection }) => {
    const [dropdown1, setDropdown1] = useState(false);
    const [dropdown2, setDropdown2] = useState(false);
    const [dropdown3, setDropdown3] = useState(false);
    const {logout}=useAuth();

    const toggleDropdown1 = () => {
        setDropdown1(!dropdown1);
    };

    const toggleDropdown2 = () => {
        setDropdown2(!dropdown2);
    };

    const toggleDropdown3 = () => {
        setDropdown3(!dropdown3);
    };

    const handleSectionClick = (section) => {
        setActiveSection(section);
    };

    const handleLogout= async()=>{
        // const response = await authService.logout();
        // const msg=response.data
        logout()
    }

    return (
        <div className="bg-gray-800 text-white w-64 flex-none flex flex-col">
            <div className="p-4 flex items-center">
                <img className="w-10 h-10 rounded-full mr-2" src="https://via.placeholder.com/50" alt="avatar" />
                <span className="text-lg font-semibold">Admin</span>
            </div>

            <div className="flex items-center justify-between px-4 py-4">
                <span className="text-white text-lg font-semibold">Gestion U&A</span>
                <button onClick={toggleDropdown1} className="text-white focus:outline-none focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                    </svg>
                </button>
            </div>
            <nav className={dropdown1 ? "block" : "hidden"}>
                <div className="py-1">
                    <a href="#" onClick={() => handleSectionClick('UserManagement')} className="text-gray-300 hover:bg-gray-700 hover:text-white block px-4 py-2 text-sm">User Management</a>
                    <a href="#" onClick={() => handleSectionClick('AppManagement')} className="text-gray-300 hover:bg-gray-700 hover:text-white block px-4 py-2 text-sm">App Management</a>
                </div>
            </nav>

            <div className="flex items-center justify-between px-4 py-4">
                <span className="text-white text-lg font-semibold">Financial Management</span>
                <button onClick={toggleDropdown2} className="text-white focus:outline-none focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                    </svg>
                </button>
            </div>
            <nav className={dropdown2 ? "block" : "hidden"}>
                <div className="py-1">
                    <a href="#" onClick={() => handleSectionClick('UpdateBalance')} className="bg-gray-900 text-white block px-4 py-2 text-sm">Update Balance</a>
                    <a href="#" onClick={() => handleSectionClick('Pricing')} className="text-gray-300 hover:bg-gray-700 hover:text-white block px-4 py-2 text-sm">Pricing</a>
                </div>
            </nav>

            <div className="flex items-center justify-between px-4 py-4">
                <span className="text-white text-lg font-semibold">Settings</span>
                <button onClick={toggleDropdown3} className="text-white focus:outline-none focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                    </svg>
                </button>
            </div>
            <nav className={dropdown3 ? "block" : "hidden"}>
                <div className="py-1">
                    <a href="#" onClick={() => handleSectionClick('AuthentificationAdmin')} className="bg-gray-900 text-white block px-4 py-2 text-sm">Save Admin Profile</a>
                </div>
            </nav>
            <div className="absolute bottom-6 left-6 px-4 py-2 rounded bg-red-600 cursor-pointer" onClick={handleLogout}>Logout</div>
        </div>
    );
};

export default Sidebar;
