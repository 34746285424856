import React, { createContext, useContext, useState } from 'react';
import authService from './authService';
import Cookies from 'js-cookie';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  
  
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated')||false);
  // const accessToken=localStorage.getItem('isAuthenticated')
  

  const login = async (email, password) => {
    try {
      const response = await authService.login(email, password);
      if (response.token && response.token !== undefined) {
        const accessToken = response.token;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('isAuthenticated',true)
        setIsAuthenticated(true);
      } else {
        throw new Error('Invalid email or password');
      }
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        throw new Error('No access token found');
      }
  
      // Make a request to the server to logout
      // await authService.logout(accessToken); // Modify according to your actual logout request
  
      // Clear access token from localStorage
      localStorage.removeItem('accessToken');
      // Set isAuthenticated to false
      localStorage.removeItem('isAuthenticated')
      setIsAuthenticated(false);
    } catch (error) {
      console.error('Logout error:', error);
      throw error;
    }
  };
  

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
