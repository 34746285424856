import React, { useState } from 'react';
import Sidebar from '../components/Dashboard/Sidebar';
import Contents from '../components/Dashboard/Contents';

const Dashboard = () => {
    const [activeSection, setActiveSection] = useState('UserManagement');

    return (
        <div className="flex h-screen overflow-hidden">
            <Sidebar setActiveSection={setActiveSection} />
            <Contents activeSection={activeSection} />
        </div>
    );
};

export default Dashboard;
