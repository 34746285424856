import React, { useEffect, useState } from 'react';
import authService from '../../../services/authService';
import ConfirmationModal from '../../../featuers/ConfirmationModal';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [entreprises, setEntreprises] = useState([]);
    const [successMsg, setSuccessMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const usersResponse = await authService.indexUser();
                const entreprisesResponse = await authService.indexEntreprise();
                setUsers(usersResponse.data);
                setEntreprises(entreprisesResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleDeleteUser = (id) => {
        setDeleteItemId(id);
        setShowConfirmationModal(true);
    };

    const handleDeleteUserConfirmed = () => {
        authService.destroyUser(deleteItemId)
            .then(response => {
                setSuccessMsg(response.data.post_success);
                setUsers(users.filter(user => user.id !== deleteItemId));
                setShowConfirmationModal(false);
            })
            .catch(error => {
                setErrorMsg('Error deleting user');
                console.error('Error deleting user:', error);
                setShowConfirmationModal(false);
            });
    };

    const handleDeleteEntreprise = (id) => {
        setDeleteItemId(id);
        setShowConfirmationModal(true);
    };

    const handleDeleteEntrepriseConfirmed = () => {
        authService.destroyEntreprise(deleteItemId)
            .then(response => {
                setSuccessMsg(response.data.post_success);
                setEntreprises(entreprises.filter(entreprise => entreprise.id !== deleteItemId));
                setShowConfirmationModal(false);
            })
            .catch(error => {
                setErrorMsg('Error deleting entreprise');
                console.error('Error deleting entreprise:', error);
                setShowConfirmationModal(false);
            });
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <h2 className="text-3xl mb-4">User and Entreprise Management</h2>
            
            {/* Success and Error Messages */}
                
            {successMsg && (
                    <div className="bg-green-200 text-green-800 p-2 rounded mt-4 px-3 flex justify-between">
                    <span>{successMsg}</span>
                    <button onClick={() => setSuccessMsg('')} className="ml-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 inline-block align-middle"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M5.293 5.293a1 1 0 011.414 0L10 8.586l3.293-3.293a1 1 0 111.414 1.414L11.414 10l3.293 3.293a1 1 0 01-1.414 1.414L10 11.414l-3.293 3.293a1 1 0 01-1.414-1.414L8.586 10 5.293 6.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                </div>
                )}
            {errorMsg && (
                <div className="bg-red-200 text-red-800 p-2 rounded mt-4 px-3 flex justify-between">
                    <span>{errorMsg}</span>
                    <button onClick={() => setErrorMsg('')} className="ml-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 inline-block align-middle"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M5.293 5.293a1 1 0 011.414 0L10 8.586l3.293-3.293a1 1 0 111.414 1.414L11.414 10l3.293 3.293a1 1 0 01-1.414 1.414L10 11.414l-3.293 3.293a1 1 0 01-1.414-1.414L8.586 10 5.293 6.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                </div>
                )}

            {/* Display Users */}
            <div className='max-h-[40vh] mb-5 overflow-auto'>
                <h3 className="text-2xl mb-2 sticky top-0 bg-white rounded py-1 px-2">Users</h3>
                {users.map(user => (
                    <div key={user.id} className="bg-gray-100 p-4 mb-2 rounded">
                        <p>{user.nm}</p>
                        <p>{user.np}</p>
                        <p>{user.email}</p>
                        {/* Display userEntreprise data if available */}
                        {user.userEntreprise.map(userEntreprise => (
                            <p key={userEntreprise.id}>Entreprise: {userEntreprise.nom_entreprise}</p>
                        ))}
                        <button onClick={() => handleDeleteUser(user.id)} className="bg-red-500 text-white px-2 py-1 rounded mt-2">Delete</button>
                    </div>
                ))}
            </div>

            {/* Display Entreprises */}
            <div className='max-h-[40vh] overflow-auto'>
                <h3 className="text-2xl mb-2 sticky top-0 bg-white rounded py-1 px-2">Entreprises</h3>
                {entreprises.map(entreprise => (
                    <div key={entreprise.id} className="bg-gray-100 p-4 mb-2 rounded">
                        <p>{entreprise.nom_entreprise}</p>
                        <p>{entreprise.tele}</p>
                        <p>{entreprise.solde}</p>
                        {/* Display userEntreprise data if available */}
                        {entreprise.userEntreprise.map(userEntreprise => (
                            <p key={userEntreprise.id}>User: {userEntreprise.nm}</p>
                        ))}
                        <button onClick={() => handleDeleteEntreprise(entreprise.id)} className="bg-red-500 text-white px-2 py-1 rounded mt-2">Delete</button>
                    </div>
                ))}
            </div>

            {/* Confirmation Modal */}
            <ConfirmationModal
                isOpen={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                onConfirm={handleDeleteUserConfirmed}
                message="Are you sure you want to delete this item?"
            />

            <ConfirmationModal
                isOpen={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                onConfirm={handleDeleteEntrepriseConfirmed}
                message="Are you sure you want to delete this item?"
            />

            

        </div>
    );
};

export default UserManagement;
