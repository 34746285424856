import React from 'react';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import { AuthProvider, useAuth } from './services/AuthContext';

function App() {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <Dashboard /> : <Login />;
}

export default () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);
