import React, { useEffect, useState } from 'react';
import { RiAddLine, RiEditLine, RiExchangeFill } from 'react-icons/ri'; 
import { BiMenu, BiX } from 'react-icons/bi';
import authService from '../../../services/authService';

const AuthentificationAdmin = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        name: '',
        currentPassword: '',
        newPassword: '',
    });

    const [successMsg, setSuccessMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const [showButtons, setShowButtons] = useState(false);
    const [showForm, setShowForm] = useState('register');

    const [btnBase, setBtnBase] = useState({ x: '', y: '' });

    const handleToggleButtons = (form) => {
        setShowButtons(!showButtons);
        setShowForm(form);
    };

    const calculatePosition = (index, totalButtons) => {
        const angle = (index / totalButtons) * Math.PI * 2;
        const radius = 40;
        const y = Math.cos(angle) * radius;
        const x = Math.sin(angle) * radius;
        return { x, y };
    };

    useEffect(() => {
        // Calculate positions for the buttons when the component mounts
        const positions = [];
        for (let i = 1; i <= 4; i++) {
            positions.push(calculatePosition(i, 4));
        }

        setBtnBase(positions);
    }, []);



    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleGetUser = () => {
        if (showForm==='updating') {
            authService.showUser()
                .then(response => setFormData({name:response.data.name,email:response.data.email}))
                .catch(error => console.error('Error updating Admin:', error));
        }
    };

    const handleUpdating = (e) => {
        e.preventDefault();
        authService.update(formData.name,formData.email)
            .then(response => console.log('Admin updating:', response.data))
            .catch(error => console.error('Error updating Admin:', error));

        setSuccessMsg('Updating successful!');
        setTimeout(() => {
            setSuccessMsg('');
        }, 3000);
    };

    const handleRegister = (e) => {
        e.preventDefault();
        authService.register(formData.name,formData.email,formData.password)
            .then(response => console.log('Admin User added:', response.data))
            .catch(error => console.error('Error adding Admin User:', error));
        setSuccessMsg('Registration successful!');
        setTimeout(() => {
            setSuccessMsg('');
        }, 3000);
    };

    const handleChangePassword = (e) => {
        e.preventDefault();
        authService.changePassword(formData.currentPassword,formData.newPassword)
            .then(response => console.log('Password changeing:', response.data))
            .catch(error => console.error('Error changeing Password:', error));

        setSuccessMsg('Password changed successfully!');
        setTimeout(() => {
            setSuccessMsg('');
        }, 3000);
    };

    return (
        <div className="flex justify-center items-center bg-gray-100">
            <div className="w-[90%] my-8">
                {/* Buttons */}
                <div className="fixed bottom-20 right-20">
                    <div className="relative">
                        {showButtons && (
                            <>
                                <button
                                    onClick={() => {handleToggleButtons('register');setFormData({name:'',email:''})}}
                                    className="bg-blue-500 text-2xl hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full shadow-lg absolute"
                                    style={{ right: -btnBase[0]?.x, bottom: -btnBase[0]?.y }}
                                >
                                    <RiAddLine/>
                                </button>
                                <button
                                    onClick={() => handleToggleButtons('changePassword')}
                                    className="bg-blue-500 text-2xl hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full shadow-lg absolute"
                                    style={{ right: -btnBase[1]?.x, bottom: -btnBase[1]?.y }}
                                >
                                    <RiExchangeFill/>
                                </button>
                                <button
                                    onClick={() => {handleToggleButtons('updating');handleGetUser()}}
                                    className="bg-blue-500 text-2xl hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full shadow-lg absolute"
                                    style={{ right: -btnBase[2]?.x, bottom: -btnBase[2]?.y }}
                                >
                                    <RiEditLine/>
                                </button>
                            </>
                        )}
                        <button
                            onClick={handleToggleButtons}
                            className={`bg-blue-500 text-2xl  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full shadow-lg absolute `}
                            style={{ right: -(btnBase[3]?.x), bottom: -btnBase[3]?.y }}
                        >
                            {showButtons ? <BiX/> : <BiMenu/>}
                        </button>
                    </div>
                </div>

                {/* Forms */}
                {showForm === 'register' && (
                    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <h2 className="text-3xl mb-4 text-center">Admin Add User</h2>
                        {/* Registration Form */}
                        <form onSubmit={handleRegister}>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-base font-bold mb-2" htmlFor="name">
                                    Name
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="name"
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Name"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-base font-bold mb-2" htmlFor="email">
                                    Email
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="email"
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Email"
                                    required
                                />
                            </div>
                            <div className="mb-6">
                                <label className="block text-gray-700 text-base font-bold mb-2" htmlFor="password">
                                    Password
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="password"
                                    type="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    placeholder="Password"
                                    required
                                />
                            </div>
                            <div className="flex items-center justify-center">
                                <button
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    type="submit"
                                >
                                    Register
                                </button>
                            </div>
                        </form>
                    </div>
                )}
                {showForm === 'changePassword' && (
                    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <h2 className="text-3xl mb-4 text-center">Change Password</h2>
                        {/* Change Password Form */}
                        <form onSubmit={handleChangePassword}>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-base font-bold mb-2" htmlFor="currentPassword">
                                    Current Password
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="currentPassword"
                                    type="password"
                                    name="currentPassword"
                                    value={formData.currentPassword}
                                    onChange={handleChange}
                                    placeholder="Current Password"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-base font-bold mb-2" htmlFor="newPassword">
                                    New Password
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="newPassword"
                                    type="password"
                                    name="newPassword"
                                    value={formData.newPassword}
                                    onChange={handleChange}
                                    placeholder="New Password"
                                    required
                                />
                            </div>
                            <div className="flex items-center justify-center">
                                <button
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    type="submit"
                                >
                                    Change Password
                                </button>
                            </div>
                        </form>
                    </div>
                )}
                {showForm === 'updating' && (
                    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <h2 className="text-3xl mb-4 text-center">Admin Updating</h2>
                        {/* Updating Form */}
                        <form onSubmit={handleUpdating}>
                            {/* Login Form */}
                            <div className="mb-4">
                                <label className="block text-gray-700 text-base font-bold mb-2" htmlFor="name">
                                    Name
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="name"
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Name"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-base font-bold mb-2" htmlFor="email">
                                    Email
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="email"
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Email"
                                    required
                                />
                            </div>
                            <div className="flex items-center justify-center">
                                <button
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    type="submit"
                                >
                                    Sign In
                                </button>
                            </div>
                        </form>
                    </div>
                )}

                {/* Success and Error Messages */}
                {successMsg && (
                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4">
                        <span className="block sm:inline">{successMsg}</span>
                        <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                            <svg
                                className="fill-current h-6 w-6 text-green-500"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M17.293 6.293a1 1 0 0 0-1.414 0L10 11.586 7.707 9.293a1 1 0 1 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l7-7a1 1 0 0 0 0-1.414z"
                                />
                            </svg>
                        </span>
                    </div>
                )}
                {errorMsg && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
                        <span className="block sm:inline">{errorMsg}</span>
                        <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                            <svg
                                className="fill-current h-6 w-6 text-red-500"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M13.293 6.293a1 1 0 0 1 1.414 1.414L11.414 11l3.293 3.293a1 1 0 1 1-1.414 1.414L10 12.414l-3.293 3.293a1 1 0 1 1-1.414-1.414L8.586 11 5.293 7.707a1 1 0 0 1 1.414-1.414L10 9.586l3.293-3.293a1 1 0 0 1 0-1.414z"
                                />
                            </svg>
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AuthentificationAdmin;
