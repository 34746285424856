import React, { useEffect, useState } from 'react';
import axios from 'axios';
import authService from '../../../services/authService';

const PricingList = () => {
    const [pricings, setPricings] = useState([]);

    useEffect(() => {
        authService.indexPricing()
            .then(response => setPricings(response))
            .catch(error => console.error('Error fetching pricings:', error));
    }, []);

    return (
        <div className="bg-white shadow-md rounded px-4 py-6">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Pricing List</h2>
            <div className='max-h-[40vh] overflow-x-auto'>
                <ul className='px-4'>
                    {pricings.map(pricing => (
                        <li key={pricing.id} className="border-b border-gray-200 py-2">
                            <div className="flex items-center justify-between">
                                <div className="flex flex-col">
                                    <span className="text-lg font-semibold text-gray-700">{pricing.name}</span>
                                    <span className="text-sm text-gray-500">{pricing.duration}</span>
                                </div>
                                <div>
                                    <span className="text-lg font-semibold text-blue-600">${pricing.pricepoint}</span>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default PricingList;
