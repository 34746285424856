import React, { useEffect, useState } from 'react';
import axios from 'axios';
import authService from '../../../services/authService';

const PackageList = () => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        authService.indexPackage()
            .then(response => setPackages(response))
            .catch(error => console.error('Error fetching packages:', error));
    }, [packages]);

    return (
        <div className="bg-white shadow-md rounded p-6">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Package List</h2>
            <div className="max-h-[32vh] overflow-x-auto">
                <table className="min-w-full">
                    <thead className='sticky top-0'>
                        <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                            <th className="py-3 px-6 text-left">Name</th>
                            <th className="py-3 px-6 text-left">Price</th>
                            <th className="py-3 px-6 text-left">Points Sold</th>
                            <th className="py-3 px-6 text-left">Duration</th>
                            <th className="py-3 px-6 text-left">State</th>
                            <th className="py-3 px-6 text-left">Duration Solde</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm font-light">
                        {packages.map(pack => (
                            <tr key={pack.id} className="border-b border-gray-200 hover:bg-gray-100">
                                <td className="py-3 px-6 text-left whitespace-nowrap">{pack.name}</td>
                                <td className="py-3 px-6 text-left whitespace-nowrap">${pack.price}</td>
                                <td className="py-3 px-6 text-left whitespace-nowrap">{pack.pointsold}</td>
                                <td className="py-3 px-6 text-left whitespace-nowrap">{pack.duration}</td>
                                <td className="py-3 px-6 text-left whitespace-nowrap">{pack.state}</td>
                                <td className="py-3 px-6 text-left whitespace-nowrap">{pack.durationSolde}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PackageList;
