import React, { useState } from 'react';
import axios from 'axios';
import authService from '../../../services/authService';

const PricingForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        pricepoint: '',
        duration: '',
        state: '',
    });

    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    
    const handleSubmit = e => {
        e.preventDefault();
        authService.storePricing(formData.name,formData.pricepoint,formData.duration)
            .then(response => console.log('Pricing added:', response.data))
            .catch(error => console.error('Error adding pricing:', error));
            setFormData({
            name: '',
            pricepoint: '',
            duration: '',
            state: ''})
    };

    return (
        <div className="bg-white shadow-md rounded px-4 py-4">
            <h2 className="text-2xl font-bold mb-3 text-gray-800">Add Pricing</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Name"
                        required
                        className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
                    />
                    <input
                        type="number"
                        name="pricepoint"
                        value={formData.pricepoint}
                        onChange={handleChange}
                        placeholder="Price Point"
                        required
                        className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
                    />
                </div>
                <div className="grid grid-cols-2 gap-4">
                    <input
                        type="text"
                        name="duration"
                        value={formData.duration}
                        onChange={handleChange}
                        placeholder="Duration"
                        required
                        className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
                    />
                    <input
                        type="text"
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                        placeholder="State"
                        required
                        className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
                    />
                </div>
                <button
                    type="submit"
                    className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                >
                    Add
                </button>
            </form>
        </div>
    );
};

export default PricingForm;
